/** 合作机构 */
<template>
  <el-carousel v-if="$isPc && partners && partners.length" ref="hxkhCardShow" indicator-position="outside"
    trigger="hover" arrow="never" :autoplay='false' :initial-index='hxkhActive'>
    <el-carousel-item v-for="(item,index) in partners" :key="index">
      <el-row :gutter="20" class="partners-list ">
        <el-col :span="6" class="partners-item mb-20" v-for="(list,index1) in item" :key="index1">
          <div class="partners-img flex-center">
            <el-image :src="$base+list.value" alt=""></el-image>
          </div>
        </el-col>
        <li class="partners-item " style="visibility:hidden;height:0;"></li>
      </el-row>
    </el-carousel-item>
  </el-carousel>
  <el-row v-else type="flex" justify="space-between" class="customer-info-one plr-10 flex-wrap">
    <el-col :span="11" class="info-one-item mb-20" v-for="(item,index) in partners" :key="index">
      <div class="partners-img flex-center">
        <el-image :src="$base+item.value" :alt="item.name" fit="contain"></el-image>
      </div>
    </el-col>
  </el-row>
</template>
<script>
export default {
  name: 'Partners',
  data () {
    return {
      partners: [],
      hxkhActive: 0
    }
  },

  mounted () {
    this.partners = this.$store.getters.configs.hzjg
    if (this.$isPc && this.partners) {
      this.partners = this.setArray(this.partners)
    }
  },
  methods: {
    // 一维数组转为二维数组
    setArray (list) {
      const newArr = []
      list.forEach((item, index) => {
        const page = Math.floor(index / 15)
        if (!newArr[page]) {
          newArr[page] = []
        }
        newArr[page].push(item)
      });
      return newArr
    }
  },
}
</script>
<style lang="scss" scoped>
.partners-img {
  border-radius: 6px;
  box-shadow: 0px 2px 9px 0px rgba(5, 20, 126, 0.31);
}
.partners-title {
  font-size: 12px;
  color: #231915;
}
/deep/.el-carousel__container {
  min-height: 510px;
}
.partners-list {
  padding: 5px;
  .partners-item {
    width: 20%;
    .partners-img {
      height: 130px;
      padding: 10px;
    }
  }
}

.customer-info-one {
  margin: 12px 0;
  .info-one-item {
    width: 48%;
    text-align: center;
    .partners-img {
      height: 50px;
      padding: 5px;
      .el-image {
        height: 100%;
      }
    }
  }
}
</style>