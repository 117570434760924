<template>
  <el-container direction="vertical">
    <!-- 头部 -->
    <el-header v-show="!$route.meta.hideHead || $isPc" :height="$isPc?'100px':'60px'" class="back-fff">
      <Header />
    </el-header>
    <!-- 主体 -->
    <el-main :class="{'layout-main':setMainAuto()}">
      <keep-alive exclude="ProductDetails,ArticleDetails,ActivityDetails,ProductForm">
        <router-view />
      </keep-alive>
    </el-main>
    <!-- 尾部 -->
    <el-footer v-show="($route.matched.some(record => record.meta.isPcHead)) || $isPc" :height="$isPc?'auto':'57px'"
      :style="{marginTop:($isPc && !($route.matched.some(record => record.meta.hideFooterTop)))?'80px':'0'}">
      <Footer v-if="$isPc" />
      <TabBar v-else />
    </el-footer>
  </el-container>
</template>
<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import TabBar from '@/components/TabBar.vue'
export default {
  name: 'Layout',
  components: {
    Header,
    Footer,
    TabBar,
  },
  methods: {
    setMainAuto () {
      if (this.$route.matched.some(record => record.path == '/my')) {
        return this.$route.matched.some(record => record.meta.mainAuto)
      } else {
        return (!this.$isPc && this.$route.matched.some(record => record.meta.mainAuto))
      }
    }
  },
}
</script>
<style lang="scss" scoped>
.el-container {
  height: 100%;
  background-color: #ffffff;
  .el-header {
    padding: 0;
  }
  .el-main {
    overflow: initial;
    &.layout-main {
      overflow: auto !important;
    }
  }
  .el-footer {
    background-color: #1d1d1d;
    color: #fff;
    text-align: center;
    padding: 20px 0;
  }
}
@media (max-width: 767px) {
  .el-container {
    background-color: #fff;
    .el-main {
      margin-top: 0;
    }
    .el-footer {
      margin-top: 0;
      background-color: #fff;
      padding: 10px 0;
    }
  }
}
</style>