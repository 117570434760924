/** 移动端导航 */
<template>
  <div class="tab-bar">
    <el-menu :default-active="activeIndex" router class="el-menu-demo" mode="horizontal" background-color="#FFFFFF"
      text-color="#333333" active-text-color="#2B62B2">
      <el-menu-item v-for="(nav,index) in tabBarNavs" :key="index" :index="nav.route_url">
        <i :class="nav.icon"></i>
        <p>{{nav.name}}</p>
      </el-menu-item>
    </el-menu>
  </div>
</template>
<script>
export default {
  name: 'TabBar',
  data () {
    return {
      activeIndex: this.$route.path,
      tabBarNavs: [
        { name: '首页', route_url: '/home', icon: 'el-icon-s-home' },
        { name: '服务分类', route_url: '/serve', icon: 'el-icon-menu' },
        { name: '线上咨询', route_url: '/counsel', icon: 'el-icon-service' },
        { name: '个人中心', route_url: '/my', icon: 'el-icon-user-solid' },
      ]
    }
  },
  watch: {
    $route (to, from) {
      this.activeIndex = to.matched.length ? to.matched[0].path : to.path;
    },
  },
  mounted () {
    let routeArr = this.$route.matched
    this.activeIndex = routeArr.length ? routeArr[0].path : this.$route.path;
  },
}
</script>
<style lang="scss" scoped>
.tab-bar {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 9999;
  background-color: #fff;
  padding: 10px 0;
  /deep/a {
    font-size: 14px;
  }
  .el-menu {
    border: none;
    display: flex;
    display: flex;
    justify-content: space-around;
    .el-menu-item {
      width: 25%;
      border: none;
      height: 100%;
      font-size: 14px;
      line-height: 1;
      text-align: center;
      i {
        font-size: 18px;
        margin: 0 0 5px;
      }
      &.is-active {
        border: none;
        background-color: transparent !important;
        font-weight: bold;
        i {
          font-weight: bold;
        }
      }
    }
  }
}
</style>