// 手机号码验证
const validatePhone = (rule, value, callback) => {
  const patter = new RegExp('^1([358][0-9]|4[579]|66|7[0135678]|9[89])[0-9]{8}$')
  if (!patter.test(value)) {
    return callback(new Error('手机号格式错误'))
  } else {
    callback() // 必须有此项回调，否则验证会一直不通过
  }
}
// 座机号验证
const validateTel = (rule, value, callback) => {
  const patter = new RegExp(/^0\d{2,3}-\d{7,8}$/)
  if (!patter.test(value)) {
    return callback(new Error('座机号格式错误'))
  } else {
    callback() // 必须有此项回调，否则验证会一直不通过
  }
}
// 密码验证
var validatePass = (rule, value, callback) => {
  /**
   * new RegExp中两种写法：
   * 1、'^(\\w){6,20}$'  // 使用字符串需使用两个 \\w
  *  2、/^(\w){6,20}$/
  */
  const pass = new RegExp(/^(\w){6,20}$/)
  if (!pass.test(value)) {
    return callback(new Error('请输入最少6位字母或数字'))
  } else {
    callback()
  }
};
// 验证码验证
var validateCode = (rule, value, callback) => {
  const pass = new RegExp('^\d{6}$')
  if (!pass.test(value)) {
    return callback(new Error('验证码格式错误'))
  } else {
    callback()
  }
};
// 用户名
var validateName = (rule, value, callback) => {
  const pass = new RegExp('^[\u0391-\uFFE5A-Za-z]+$')
  if (!pass.test(value)) {
    return callback(new Error('请输入2~6位字母或汉字'))
  } else {
    callback()
  }
};
// 公司名称
var validateCompanyName = (rule, value, callback) => {
  const pass = new RegExp('^[\u4E00-\u9FA5A-Za-z]([A-Za-z0-9\u4e00-\u9fa5]{1,19})+$')
  if (!pass.test(value)) {
    return callback(new Error('请输入2~20位字母、数字、汉字，且以汉字或字母开头'))
  } else {
    callback()
  }
};
// 申请额度
var validateApplyCredit = (rule, value, callback) => {
  const pass = new RegExp('^[0-9]+(.?[0-9]{1,2})?$')
  if (value && !pass.test(value)) {
    return callback(new Error('只能填写数字，并且最多可保留两位小数'))
  } else {
    callback()
  }
};
// 邮箱的正则
const validateEmail = (rule, value, callback) => {
  const mailbox = new RegExp('^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$')
  if (!mailbox.test(value)) {
    return callback(new Error('邮箱格式错误'))
  } else {
    callback()
  }
}
// 身份证号
const validateIdCardNo = (rule, value, callback) => {
  const mailbox = new RegExp('^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}[0-9Xx]$')
  if (!mailbox.test(value)) {
    return callback(new Error('请输入正确格式的身份证号！'))
  } else {
    callback()
  }
}
export default {
  common: {
    // ---------------------公共验证方法
    // 手机号
    phone: [
      { required: true, message: '手机号不能为空' },
      { validator: validatePhone, trigger: 'blur' }
    ],
    // 座机号
    tel: [
      { required: true, message: '座机号不能为空' },
      { validator: validateTel, trigger: 'blur' }
    ],
    // 密码
    password: [
      { required: true, message: '请填写登录密码' },
      { validator: validatePass, trigger: 'blur' }
    ],
    // 验证码
    captcha: [
      { required: true, message: '验证码不能为空' },
      { validator: validatePass, trigger: 'blur' }
    ],
    // 姓名
    name: [
      { required: true, message: '用户名不能为空' },
      { min: 2, max: 6, message: '请输入2~6位字母或汉字' },
      { validator: validateName, trigger: ['blur', 'change'] }
    ],
    // 公司名称
    company_name: [
      { required: true, message: '公司名称不能为空' },
      { min: 2, max: 20, message: '请输入2~20位字母、数字、汉字，且以汉字或字母开头' },
      { validator: validateCompanyName, trigger: ['blur', 'change'] }
    ],
    // 申请额度
    apply_credit: [
      { validator: validateApplyCredit, trigger: ['blur', 'change'] }
    ],
    // 邮箱
    email: [
      { required: true, message: '邮箱不能为空' },
      { validator: validateEmail, trigger: 'blur' }
    ],
    // 邮箱
    mailbox: [
      { required: true, message: '邮箱不能为空' },
      { validator: validateEmail, trigger: 'blur' }
    ],
    // 身份证号码
    idCardNo: [
      { required: true, message: '身份证号码不能为空' },
      { validator: validateIdCardNo, trigger: 'blur' }
    ],
  },
  handle: {
    // ---------------------处理表单的验证方法
    operResult: [{//处理结果
      required: true,
      message: '请选择处理结果'
    }],
    opinion: [{//处理结果描述
      required: true,
      message: '请填写处理结果描述'
    }]
  },
}