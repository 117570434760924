/** 首页 */
<template>
  <div class="home mb-30">
    <!-- banner图 -->
    <section class="home-banner mb-20">
      <swiper v-if="banners && banners.length" ref="mySwiper" :options="swiperOptionsFun()">
        <swiper-slide v-for="(item,index) in banners" :key="index">
          <img :src="$base+item.value" :alt="item.group_name" />
        </swiper-slide>
      </swiper>
    </section>
    <!-- 导航 -->
    <section v-if="!$isPc" class="home-navs plr-10">
      <el-row :gutter="20" class="navs-list">
        <el-col :span="8" class="navs-item" v-for="(item,index) in wapNavs" :key="index">
          <router-link :to="item.route_url">
            <el-image :src="item.img" class="navs-img mb-10" fit="contain"></el-image>
            <p class="navs-name">{{item.name}}</p>
          </router-link>
        </el-col>
      </el-row>
    </section>
    <!-- 服务动态 -->
    <section v-if="!$isPc" class="home-dynamic content">
      <!-- 移动端 -->
      <div class="ipbs-title">
        <h4>服务动态</h4>
      </div>
      <div v-if="dynamicApply && dynamicApply.length" class="dynamic-con dynamic-con-mobile flex plr-10">
        <swiper ref="mySwiper" :options="dynamicSwiperFun(dynamicApply.length>4)">
          <swiper-slide v-for="(item,index) in dynamicApply" :key="index">
            <el-row type="flex">
              <el-col :xs="10"><span>{{item.company_name|filterStr}}</span></el-col>
              <el-col :xs="8" class="text-ellipsis">
                <strong>融资{{item.credit_line}}万</strong>
              </el-col>
              <el-col :xs="4" class="flex-1"><span>{{item.created_at}}</span></el-col>
            </el-row>
          </swiper-slide>
        </swiper>
      </div>
    </section>
    <!-- 金融产品 -->
    <section class="home-fp content">
      <div class="ipbs-title">
        <h4>{{$isPc?'明星产品':'金融产品'}}</h4>
        <el-button v-if="!$isPc" type='text' @click="$router.push('/serve/product')">
          查看更多<i class="el-icon-arrow-right el-icon--right"></i>
        </el-button>
      </div>
      <template v-if="product.length">
        <el-row type="flex" :gutter="$isPc?20:0" :class="['fp-list','flex-wrap',{'plr-10':!$isPc}]">
          <el-col :xs='12' :sm="12" :md="6" v-for="(item,index) in product" :key="index" class="fp-item">
            <router-link :to="{ path: '/product_details', query: { id: item.id } }">
              <div class="product-info">
                <div :class="['product-img', 'flex-center',$isPc?'mb-30':'mb-10' ]">
                  <el-image :src="$base+item.product_logo" fit="contain"> </el-image>
                </div>
                <div class="product-desc">
                  <p class="product-name flex-center"><strong>{{item.product_name}}</strong></p>
                  <p class="product-text text-ellipsis-lines">{{item.product_abstract}}</p>
                </div>
              </div>
            </router-link>
          </el-col>
        </el-row>
        <el-button v-if="$isPc" type="info" class="ipbs-more">
          <router-link to="/serve/product">查看更多</router-link>
        </el-button>
      </template>
      <div v-else v-loading="fpLoading" class="loading-con"></div>
    </section>
    <!-- 活动专场 -->
    <section class="past-activity content">
      <div class="ipbs-title">
        <h4>活动专场</h4>
        <el-button v-if="!$isPc" type='text' @click="$router.push('/activity')">
          查看更多<i class="el-icon-arrow-right el-icon--right"></i>
        </el-button>
      </div>
      <template v-if="activity.length">
        <el-row :gutter="$isPc?20:0" :class="['activity-list', {'plr-10':!$isPc}]">
          <el-col :xs='24' :sm="12" :md="6" :class="['activity-item', {'mb-20':$isPc}]" v-for="(item,index) in activity"
            :key="index">
            <router-link :to="{path:'/activity_details',query:{id:item.id}}" :class="{flex:!$isPc}">
              <div class="activity-img">
                <el-image :class="[{'flex-1':!$isPc}]" :src="$base+item.activity_logo"> </el-image>
                <div v-if="$isPc" class="activity-desc">
                  <p>{{item.activity_unit}}</p>
                  <el-image :src="require('@/assets/images/arrow-right.png')" fit="cover"></el-image>
                </div>
              </div>
              <div :class="['activity-info', 'flex-column', $isPc?'justify-between':'ml-20 justify-around', 'flex-1']">
                <h4 class="activity-title text-ellipsis">{{item.activity_name}}</h4>
                <el-row :gutter="10" class="activity-info-list">
                  <el-col :xs="24" :sm="13" class="activity-info-item text-ellipsis">
                    <el-image :src="require('@/assets/images/home-activity3.png')"></el-image>
                    {{item.activity_date|filterTime}}
                  </el-col>
                  <el-col :xs="24" :sm="11" class="activity-info-item text-ellipsis">
                    <el-image :src="require('@/assets/images/home-activity2.png')"></el-image>
                    {{item.activity_address}}
                  </el-col>
                </el-row>
              </div>
            </router-link>
          </el-col>
        </el-row>
        <el-button v-if="$isPc" type="info" class="ipbs-more">
          <router-link to="/activity">查看更多</router-link>
        </el-button>
      </template>
      <div v-else v-loading="activityLoading" class="loading-con"></div>
    </section>
    <!-- 金融政策 -->
    <section class="home-policy content">
      <div class="ipbs-title">
        <h4>金融政策</h4>
        <el-button v-if="!$isPc" type='text' @click="$router.push('/policy')">
          查看更多<i class="el-icon-arrow-right el-icon--right"></i>
        </el-button>
      </div>
      <el-row v-if="policyData && policyData.length" :gutter="$isPc?34:0" :class="{'plr-10':!$isPc}">
        <el-col :xs="24" :sm="12" v-for="(item,index) in policyData" :key="index">
          <router-link :to="{ path: '/article_details', query: { id: item.id, type: 'policy' } }"
            class="policy-item justify-between align-center">
            <span class="policy-title text-ellipsis">{{item.title}}</span>
            <span class="policy-date">{{item.created_at|filterTime}}</span>
          </router-link>
        </el-col>
      </el-row>
      <div v-else v-loading="policyLoading" class="loading-con"></div>
    </section>
    <!-- 新闻动态、服务动态 -->
    <section class="home-yb content mb-30">
      <el-row type="flex" :gutter="$isPc?34:0" class="yb-main">
        <el-col :xs="24" :sm="12">
          <div class="ipbs-title">
            <h4>新闻动态</h4>
            <el-button v-if="!$isPc" type='text' @click="$router.push('/news')">
              查看更多<i class="el-icon-arrow-right el-icon--right"></i>
            </el-button>
          </div>
          <div v-if="newsData && newsData.length" :class="['yb-con',{'plr-10':!$isPc}]">

            <el-image v-if="$isPc" class="news-img" :src="require('@/assets/images/home-new.png')"></el-image>

            <el-row :gutter="$isPc?34:0" class="yb-list">
              <el-col :xs="24" v-for="(item,index) in newsData" :key="index">
                <router-link :to="{ path: '/article_details', query: { id: item.id, type: 'news' } }"
                  class="yb-item justify-between align-center">
                  <span class="yb-title text-ellipsis">{{item.title}}</span>
                  <span class="yb-date">{{item.created_at|filterTime}}</span>
                </router-link>
              </el-col>
            </el-row>
          </div>
          <div v-else v-loading="newsLoading" class="loading-con"></div>
        </el-col>

        <el-col v-if="$isPc" :span="12" class="flex-1">
          <div class="ipbs-title" style="padding:0 20px;">
            <h4>服务动态</h4>
          </div>
          <div v-if="dynamicApply && dynamicApply.length" class="dynamic-con flex">
            <swiper ref="mySwiper" :options="dynamicSwiperFun(dynamicApply.length>10)">
              <swiper-slide v-for="(item,index) in dynamicApply" :key="index">
                <el-row type="flex">
                  <el-col :sm="10"><span>{{item.company_name|filterStr}}</span></el-col>
                  <el-col :sm="8" class="text-ellipsis">
                    <strong>融资{{item.credit_line}}万</strong>
                  </el-col>
                  <el-col :sm="4" class="flex-1"><span>{{item.created_at}}</span></el-col>
                </el-row>
              </swiper-slide>
            </swiper>
          </div>
          <div v-else v-loading="dyLoading" class="loading-con"></div>
        </el-col>
      </el-row>
    </section>
    <!-- 综合服务 -->
    <section class="home-ptys">
      <div class="content">
        <div class="ipbs-title">
          <h4>平台优势</h4>
        </div>
      </div>

      <el-row :gutter="$isPc?34:0" :class="[$isPc?'ptys-list':'ptys-list-mobile']">
        <el-col :span="24" v-for="(item,index) in ptys" :key="index" :class="['ptys-item',{'flex':$isPc}]">
          <el-image v-if="$isPc?!(index%2):true" class="ptys-img " :src="item.img" fit="cover"></el-image>
          <div class="ptys-info flex-1">
            <h4 class="ptys-name" :style="{color:item.color}">{{item.name}}</h4>
            <p class="ptys-desc">{{item.desc}}</p>
            <el-button class="ptys-btn" :style="{backgroundColor:item.color}" round>
              {{index==2?'进入平台':'进入系统'}}
            </el-button>
          </div>
          <el-image v-if="$isPc?!!(index%2):false" class="ptys-img " :src="item.img" fit="cover"> </el-image>
        </el-col>
      </el-row>
    </section>
    <!-- 合作机构 -->
    <section v-if="$isPc" class="home-hzjg content">
      <div class="ipbs-title">
        <h4>合作机构</h4>
      </div>
      <Partners />
    </section>
  </div>
</template>

<script>
import { getDynamic } from '@/api/index'
import { getNewArticle } from '@/api/article'
import { getProduct } from '@/api/product'
import { getAllActivity } from '@/api/activity'
import Partners from '@/views/Partners/index.vue'
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
export default {
  name: 'Home',
  components: {
    Partners,
    swiper,
    swiperSlide
  },
  data () {
    return {
      banners: [],
      wapNavs: [
        { name: '找产品', route_url: '/serve/product', img: require('@/assets/images/home-wap1.png') },
        { name: '看政策', route_url: '/policy', img: require('@/assets/images/home-wap2.png') },
        { name: '找活动', route_url: '/activity', img: require('@/assets/images/home-wap3.png') },
      ],

      dynamicApply: [],// 服务动态--申请融资
      product: [],// 金融产品
      policyData: [],//金融政策
      newsData: [],//政策
      activity: [],// 活动专场
      // 综合服务
      ptys: [
        { router_url: '#', color: '#4355D8', name: '中知云评估系统', img: require('@/assets/images/home-serve1.png'), desc: '中知云评估系统是中知在线基于《资产评估法》、《资产评估准则》开发的知识产权评估工具，有效提高资产评估效率，在将评估环节、参数选取标准化的同时，减少人为判断导致的结果误差，可以出具法定的知识产权资产评估报告。' },
        { router_url: '#', color: '#713AE0', name: '中知云风控系统', img: require('@/assets/images/home-serve2.png'), desc: '中知云风控系统是中知在线开发的知识产权大数据风控平台，通过大数据、人工智能方式整合知识产权、工商、司法诉讼、经营风险、人员风险、新闻舆情等100余类大数据，对企业知识产权风险、信用风险进行分析、监控和预警。' },
        { router_url: '#', color: '#1CABE6', name: '知识产权交易平台', img: require('@/assets/images/home-serve3.png'), desc: '与高校、科研院所、金融机构合作搭建知识产权许可交易平台，并成功运营了多场高价值知识产权资产包许可、交易、拍卖活动。' },
      ],
      fpLoading: true,
      policyLoading: true,
      newsLoading: true,
      dyLoading: true,
      activityLoading: true,
    }
  },
  mounted () {
    this.banners = this.$isPc ? this.$store.getters.configs.home_banner : this.$store.getters.configs.mobile_banner
    this.getDynamic()
    this.getProduct()
    this.getAllActivity()
    this.getPolicyArticle()
    this.getNewsArticle()
  },
  methods: {
    // banner轮播
    swiperOptionsFun () {
      return {
        loop: (this.banners.length > 1 ? true : false),
        //自动播放
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,//触摸后再次自动轮播
        },
      }
    },
    // 交易动态滚动
    dynamicSwiperFun (type) {
      return {
        direction: 'vertical',
        loop: type,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,//触摸后再次自动轮播
        },
        slidesPerView: 'auto',//设置slider容器能够同时显示的slides数量
      }
    },
    // 服务动态
    async getDynamic () {
      try {
        let { data } = await getDynamic()
        this.dynamicApply = data.finance || []
        this.dyLoading = false
      } catch (error) { }
    },
    // 金融产品
    async getProduct () {
      try {
        let { data } = await getProduct({ show: 2, limit: 4 })
        this.product = data
        this.fpLoading = false
      } catch (error) { }
    },
    // 金融政策
    async getPolicyArticle () {
      try {
        let { data } = await getNewArticle({ article_type: '政策' })
        this.policyData = data
        this.policyLoading = false
      } catch (error) { }
    },
    // 新闻动态
    async getNewsArticle () {
      try {
        let { data } = await getNewArticle({ article_type: '新闻动态', limit: 6 })
        this.newsData = data
        this.newsLoading = false
      } catch (error) { }
    },
    // 活动专场
    async getAllActivity () {
      try {
        let { data } = await getAllActivity({ page: 1, limit: 4 })
        this.activity = data
        this.activityLoading = false
      } catch (error) { }
    },
  }
}
</script>
<style lang="scss" scoped>
.el-col {
  a {
    width: 100%;
    height: 100%;
  }
}
.ipbs-title {
  h4 {
    text-align: left;
    color: #4c4c4c;
  }
}
// 服务动态公共
.dynamic-con {
  overflow: hidden;
  .swiper-container {
    width: 100%;
    margin: 0;
    .swiper-slide {
      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: -4px;
        display: inline-block;
        width: 8px;
        height: 8px;
        background-color: #151933;
      }
    }
    .el-row {
      padding-left: 20px;
      .el-col:last-child {
        text-align: right;
      }
    }
  }
}
// banner图
/deep/ .home-banner {
  img {
    width: 100%;
  }
}
// 导航
.home-navs {
  .navs-list {
    .navs-item {
      text-align: center;
      .navs-img {
        padding: 10px 20px;
        border-radius: 6px;
        background-color: #357eed;
        height: 40px;
      }
    }
  }
}
// 服务动态
.home-dynamic {
  overflow: hidden;
  .dynamic-con-mobile {
    padding: 0 10px;
    height: 124px;
    overflow: hidden;
    .swiper-container {
      .swiper-slide {
        height: 30px !important;
        line-height: 30px;
      }
      .el-row {
        border-bottom: 1px dashed #c8c8c8;
        .el-col {
          font-size: 14px;
          text-align: left;
        }
      }
    }
  }
}
// 金融产品
.home-fp {
  .fp-list {
    .fp-item {
      margin-bottom: 15px;
      .product-info {
        padding: 0;
        box-shadow: 0px 0px 18px 0px rgba(153, 187, 255, 0.66);
        overflow: hidden;
        .product-img {
          width: 180px;
          height: 180px;
          padding: 10px;
          margin: 20px auto;
          border-radius: 50%;
          .el-image {
            height: auto;
          }
        }
        .product-desc {
          // height: 160px;
          padding: 10px 20px 20px;
          .product-name {
            // height: auto;
            font-size: 34px;
          }
          .product-text {
            font-size: 14px;
            line-height: 24px;
          }
        }
      }
      &:nth-child(1) {
        .product-img {
          border: 2px solid #0d4fd4;
        }
        .product-desc {
          background: linear-gradient(132deg, #c0d5ff 0%, #91a5fb 100%);
        }
      }
      &:nth-child(2) {
        .product-img {
          border: 2px solid #2c6fcc;
        }
        .product-desc {
          background: linear-gradient(132deg, #bdd9ff 0%, #6caaff 100%);
        }
      }
      &:nth-child(3) {
        .product-img {
          border: 2px solid #005d40;
        }
        .product-desc {
          background: linear-gradient(132deg, #c1f7e6 0%, #4ebb99 100%);
        }
      }
      &:nth-child(4) {
        .product-img {
          border: 2px solid #d83526;
        }
        .product-desc {
          background: linear-gradient(132deg, #f8c9c4 0%, #e8948f 100%);
        }
      }
    }
  }
}

// 活动专场
.past-activity {
  .activity-list {
    .activity-item {
      a {
        transition: all 0.3s;
        .activity-img {
          position: relative;
          .activity-desc {
            width: 100%;
            height: 100%;
            background-color: #3c51e0;
            position: absolute;
            top: 0;
            left: -100%;
            transition: all 0.3s;
            text-align: center;
            p {
              color: #fff;
              font-size: 14px;
              line-height: 32px;
              padding: 0 20px;
              position: absolute;
              top: 40%;
              transform: translateY(-50%);
            }
            .el-image {
              position: absolute;
              bottom: 20px;
              left: 46%;
            }
          }
        }
        .activity-info {
          padding: 20px;
        }
      }
      &:hover {
        a {
          box-shadow: 0px 0px 13px 0px rgba(70, 70, 70, 0.61);
          .activity-img {
            .activity-desc {
              left: 0;
            }
          }
        }
      }
    }
  }
}
// 金融政策
.home-policy {
  .policy-item {
    width: 93%;
    padding: 20px;
    .policy-title {
      width: 80%;
      line-height: 24px;
    }
    &:hover {
      border-radius: 37px;
      background-color: #3c51e0;
      color: #fff;
    }
  }
}
// 服务政策
.yb-main {
  // 左侧
  .yb-con {
    .news-img {
      height: 240px;
    }
    .yb-list {
      .yb-item {
        height: 58px !important;
        line-height: 58px;
        &:hover {
          color: #007aff;
        }
        .yb-title {
          width: 80%;
        }
        .yb-date {
          color: #6e6e6e;
        }
      }
    }
  }

  // 右侧列表
  .dynamic-con {
    padding: 0 20px;
    height: 582px;
    overflow: hidden;
    position: relative;
    .swiper-container {
      margin-top: -20px;
      .swiper-slide {
        height: 60px !important;
        line-height: 60px;
        &::before {
          border-radius: 50%;
          background-color: #b1b1b1;
        }
        .el-row {
          border-bottom: 1px solid #c8c8c8;
        }
      }
    }
  }
}
// 综合服务
.home-ptys {
  .ptys-list {
    .ptys-item {
      margin-bottom: 40px;
      &:nth-child(odd) {
        .ptys-info {
          margin-right: 13%;
          padding-left: 40px;
        }
      }
      &:nth-child(even) {
        .ptys-info {
          margin-left: 13%;
          padding-right: 40px;
        }
      }
      .ptys-img {
        width: 50%;
        height: 280px;
      }
      .ptys-info {
        align-self: center;
        .ptys-name {
          font-size: 24px;
        }
        .ptys-desc {
          color: #10092c;
          line-height: 32px;
          margin: 30px auto;
        }
        .ptys-btn {
          color: #fff;
          float: right;
        }
      }
    }
  }
}
// 分页
/deep/ .page {
  justify-content: flex-end;
  padding: 0 35px 50px;
  .el-button {
    background-color: #ececec;
    &.is-disabled {
      background-color: #ececec;
    }
    &:hover,
    &:focus {
      background-color: #ececec;
    }
    &.active {
      background-color: #409eff;
    }
  }
}
@media (min-width: 1400px) {
  .home-fp {
    .fp-list {
      .fp-item {
        .product-info {
          .product-img {
            width: 218px;
            height: 218px;
          }
        }
      }
    }
  }
}
@media (min-width: 1300px) and (max-width: 1399px) {
  .home-ptys {
    .ptys-list {
      .ptys-item {
        &:nth-child(odd) {
          .ptys-info {
            margin-right: 7%;
          }
        }
        &:nth-child(even) {
          .ptys-info {
            margin-left: 7%;
          }
        }
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 1299px) {
  .home-ptys {
    .ptys-list {
      .ptys-item {
        &:nth-child(odd) {
          .ptys-info {
            margin-right: 3%;
          }
        }
        &:nth-child(even) {
          .ptys-info {
            margin-left: 3%;
          }
        }
      }
    }
  }
}
@media (max-width: 767px) {
  .el-image {
    border-radius: 0;
  }
  // 金融产品
  .home-fp {
    margin-top: 10px;
    .fp-list {
      justify-content: space-between;
      .fp-item {
        width: 48%;
        .product-info {
          .product-img {
            width: 80px;
            height: 80px;
            padding: 30px;
          }
          .product-desc {
            // height: 92px;
            padding: 10px;
            .product-name {
              font-size: 18px;
            }
          }
        }
      }
    }
  }
  .home-policy {
    .policy-item {
      width: 100%;
      padding: 10px 0;
      border-bottom: 1px solid #e3e3e3;
      .policy-title {
        width: 70%;
        font-weight: bold;
      }
      &:hover {
        border-radius: 0;
        background-color: transparent;
        color: #000;
      }
    }
  }
  .yb-main {
    .yb-con {
      .yb-list {
        .yb-item {
          .yb-title {
            width: 70%;
          }
        }
      }
    }
  }
  .home-ptys {
    .ptys-list-mobile {
      .ptys-item {
        text-align: center;
        .ptys-info {
          padding: 20px 10px;
          .ptys-name {
            font-size: 18px;
          }
          .ptys-desc {
            color: #10092c;
            line-height: 24px;
            margin: 10px auto;
          }
          .ptys-btn {
            color: #fff;
            padding: 10px;
            font-size: 14px;
          }
        }
      }
    }
  }
}
</style>