import request from "@/utils/request.js"

// 网站配置
export const getConfig = () => {
  return request({
    method: 'get',
    url: '/config'
  })
}


// 交易动态
export const getDynamic = () => {
  return request({
    method: 'get',
    url: '/gettrade',
  })
}

// 运营联盟
export const getUnion = (data) => {
  return request({
    method: 'get',
    url: '/getopreateinfo',
    params: data
  })
}