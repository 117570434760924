import axios from 'axios'
import store from '@/store'
import { Loading, Message } from 'element-ui'
import { CacheUtils } from './cacheUtils.js';
const request = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL
})



// 请求拦截器
request.interceptors.request.use((config) => {

  // 利用逻辑运算符简化if嵌套
  if (config.headers.token && store.getters.userToken) {
    config.headers.Authorization = `Bearer ${store.getters.userToken}`
    delete config.headers.token
  }

  if (config.headers.isStop) {
    let cacheKey = config.url;
    // 每次发送请求之前将上一个未完成的相同请求进行中断
    CacheUtils.cache[cacheKey] && CacheUtils.clearCache(cacheKey);

    // 将当前请求所对应的取消函数存入缓存
    config.cancelToken = new axios.CancelToken(function executor (c) {
      CacheUtils.cache[cacheKey] = c;
    });
  }

  return config
}, (error) => {
  return Promise.reject(error)
}
)
// 响应拦截器
request.interceptors.response.use((response) => {
  // 响应接收之后清除缓存
  const cacheKey = response.config.cacheKey;
  delete CacheUtils.cache[cacheKey];

  if (response.data.code == 4001) {
    Message.error('登录过期，请重新登录')
    store.dispatch('setUserToken', '')
    return Promise.reject(response.data || response)
  } else {
    return response.data || response
  }

},
  // 响应错误
  async (error) => {
    // 响应异常清除缓存
    if (error.config) {
      const cacheKey = error.config.cacheKey;
      delete CacheUtils.cache[cacheKey];
    }
    return Promise.reject(error)
  }
)
export default request