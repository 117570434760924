// 金融产品
import request from "@/utils/request.js"

// 金融产品
export const getProduct = (data) => {
  return request({
    method: 'get',
    url: '/getproduct',
    params: data
  })
}

// 产品详情
export const getProductDetails = (id) => {
  return request({
    method: 'get',
    url: '/getproductinfo',
    params: { id }
  })
}

// 增加产品浏览量
export const setReadProduct = (id) => {
  return request({
    method: 'post',
    url: '/readproduct',
    data: { id }
  })
}
// 相关推荐
export const getProductOther = (recomlines, deadline) => {
  return request({
    method: 'get',
    url: '/getrecommend',
    params: { recomlines, deadline }
  })
}
// 产品申请
export const applyProduct = (data) => {
  return request({
    method: 'post',
    url: '/applyproduct',
    headers: {
      token: true
    },
    data
  })
}