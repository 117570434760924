import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home/index.vue'
import store from '@/store'
import { setItem, getItem, removeItem } from '@/utils/storage'
Vue.use(VueRouter)
/**
 * meta属性详解
 * 
 * loginAuth:验证登录
 * mainAuto:有二级路由时使用
 * hideNav:隐藏导航
 * hideHead:隐藏头部
 * isPcHead:PC端/移动端头部切换 &&  展示移动端tabBar
 * isWap:当前路由只在移动端显示
 * isDetails:详情页
 * detailsTitle:移动端头部标题内容
 * hideBackTop:‘置顶’按钮隐藏
 * hideFooterTop:删除底部margin-top值
 */
const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  // 首页
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: {
      isPcHead: true
    }
  },
  // 登录/注册
  {
    path: '/account',
    name: 'Account',
    component: () => import('@/views/Account/index.vue'),
    meta: {
      hideNav: true,
      hideBackTop: true,
      hideFooterTop: true
    },
    children: [
      // 登录
      {
        path: 'login',
        name: 'Login',
        component: () => import('@/views/Account/login.vue'),
        meta: {
          isDetails: true,
          detailsTitle: '登录'
        }
      },
      // 注册
      {
        path: 'register',
        name: 'Register',
        component: () => import('@/views/Account/register.vue'),
        meta: {
          isDetails: true,
          detailsTitle: '注册'
        }
      },
      // 重置密码
      {
        path: 'pass_back',
        name: 'PassBack',
        component: () => import('@/views/Account/passBack.vue'),
        meta: {
          isDetails: true,
          detailsTitle: '重置密码'
        }
      }
    ]
  },
  // 服务中心
  {
    path: '/serve',
    name: 'Serve',
    component: () => import('@/views/Serve/index.vue'),
    redirect: '/serve/product',
    meta: {
      mainAuto: true,
      isPcHead: true
    },
    children: [
      // 金融超市
      {
        path: 'product',
        name: 'Product',
        component: () => import('@/views/Product/index.vue'),
        meta: {
          hideHead: true,
        }
      },
      // 合作机构
      {
        path: 'partners',
        name: 'Partners',
        component: () => import('@/views/Partners/index.vue'),
        meta: {
          isWap: true,
          hideHead: true,
        }
      },
    ]
  },
  // 金融超市-详情
  {
    path: '/product_details',
    name: 'ProductDetails',
    component: () => import('@/views/Product/details.vue'),
    meta: { isDetails: true, detailsTitle: '产品详情' }
  },
  // 金融超市-金融产品申请
  {
    path: '/product_form',
    name: 'ProductForm',
    component: () => import('@/views/Product/Pform.vue'),
    meta: { isWap: true, detailsTitle: '申请金融产品' }
  },

  // 政策
  {
    path: '/policy',
    name: 'Policy',
    component: () => import('@/views/Policy/index.vue'),
    meta: {
      detailsTitle: '政策服务',
    }
  },
  // 新闻
  {
    path: '/news',
    name: 'News',
    component: () => import('@/views/News/index.vue'),
    meta: {
      detailsTitle: '新闻动态',
    }
  },
  // 文章详情
  {
    path: '/article_details',
    name: 'ArticleDetails',
    component: () => import('@/components/articleDetails.vue'),
    meta: { isDetails: true, detailsTitle: '文章详情' }
  },
  // 运营联盟
  {
    path: '/union',
    name: 'Union',
    component: () => import('@/views/Union/index.vue'),
    meta: { detailsTitle: '运营联盟' }
  },

  // 活动专场
  {
    path: '/activity',
    name: 'Activity',
    component: () => import('@/views/Activity/index.vue'),
    meta: {
      detailsTitle: '活动专场',
    }
  },
  // 活动专场-列表
  {
    path: '/activity_list',
    name: 'ActivityList',
    component: () => import('@/views/Activity/list.vue'),
    meta: { isDetails: true, detailsTitle: '活动列表' }
  },
  // 活动专场-详情
  {
    path: '/activity_details',
    name: 'ActivityDetails',
    component: () => import('@/views/Activity/details.vue'),
    meta: { isDetails: true, detailsTitle: '活动详情' }
  },

  // 评估系统
  {
    path: '/assessment',
    name: 'Assessment',
    component: () => import('@/views/Assessment/index.vue'),
    meta: { detailsTitle: '中知云评估系统' }
  },

  // 线上咨询
  {
    path: '/counsel',
    name: 'Counsel',
    component: () => import('@/views/Counsel/index.vue'),
    meta: {
      isWap: true,
      hideHead: true,
      hideBackTop: true,
      isPcHead: true
    }
  },
  // 关于我们
  {
    path: '/about_us',
    name: 'AboutUs',
    component: () => import('@/views/AboutUs/index.vue'),
    meta: {
      detailsTitle: '关于我们'
    }
  },
  // 个人中心
  {
    path: '/my',
    name: 'My',
    component: () => import('@/views/My/index.vue'),
    meta: {
      loginAuth: Vue.prototype.$isPc ? true : false,
      mainAuto: true,
      hideHead: true,
      isPcHead: true,
      hideFooterTop: true
    },
    children: [
      {
        path: 'info',
        name: 'Info',
        component: () => import('@/views/My/info.vue'),
        meta: {
          loginAuth: true,
          detailsTitle: '账号管理'
        }
      },
      // 我的订单
      {
        path: 'order',
        name: 'Order',
        component: () => import('@/views/My/order.vue'),
        meta: {
          loginAuth: true,
          detailsTitle: '我的订单'
        }
      },
      // 我的收藏
      {
        path: 'collect',
        name: 'Collect',
        component: () => import('@/views/My/collect.vue'),
        meta: {
          loginAuth: true,
          detailsTitle: '我的收藏'
        }
      },
      // 账号设置
      {
        path: 'account_settings/:type',
        name: 'AccountSettings',
        component: () => import('@/views/My/AccountSettings.vue'),
        meta: {
          loginAuth: true,
          isDetails: true,
        }
      },
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})

router.beforeEach((to, from, next) => {
  // 设置网站搜索关键字
  let head = document.getElementsByTagName('head');
  let meta = document.createElement('meta');
  /** keywords-网页关键字 description-网站内容描述 */
  document.querySelector('meta[name="keywords"]').setAttribute('content', '知识产权证券化,知识产权资产证券化,知识产权证券化产品,知识产权证券化融资,知识产权证券化服务,知识产权证券化中金浩,专利证券化融资,专利资产证券化,商标证券化,中技所知识产权证券化,知识产权证券化交易,国内知识产权证券化,技术产权证券化,知识产权ABS,版权证券化,知识产权金融研究院')
  document.querySelector('meta[name="description"]').setAttribute('content', '')
  meta.content = to.meta.content;
  head[0].appendChild(meta)

  var u = navigator.userAgent;
  var IOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
  // 修复iOS版微信分享HTML5 History兼容性问题
  if (IOS && !getItem('isIOS')) {
    setItem("isIOS", true)
    setItem("firstUrl", window.location.href.split('#')[0])
  }

  // 是否登录
  let isLogin = store.getters.userToken
  // 未登录状态；当路由到nextRoute指定页时，跳转至login
  if ((to.matched.some(record => record.meta.loginAuth))) {
    if (!isLogin) {
      next({ path: '/account/login', query: { redirect: to.fullPath } })
    } else {
      next()
    }
  }
  if (isLogin && (to.name == 'Login' || to.name == 'Register')) {
    removeItem('router_url')
    next({ path: from.fullPath })
  }
  if (Vue.prototype.$isPc && to.meta.isWap) {
    next('/')
  }
  if (Vue.prototype.$isPc && to.name == 'My') {
    next({ path: '/my/order' })
  }
  next()
})

// 避免重复点击路由报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
export default router
