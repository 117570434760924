import Vue from 'vue'
import Vuex from 'vuex'
import { setItem, getItem } from '@/utils/storage'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userToken: getItem('USER_TOKEN') || '',
    userInfo: getItem('USER_INFO') || null,
    configs: null, // 网站配置
    showApply: false,//金融产品申请框
  },
  mutations: {
    SET_USER_TOKEN (state, value) {
      state.userToken = value
      setItem('USER_TOKEN', value)
      state.showAccount = false
    },
    SET_USER_INFO (state, value) {
      state.userInfo = value
      setItem('USER_INFO', value)
    },
    SET_CONFIGS (state, payload) {
      state.configs = payload
    },
    SET_SHOW_APPLY (state, value) {
      state.showApply = value
    },
  },
  actions: {
    // 存储用户token
    setUserToken (context, val) {
      context.commit('SET_USER_TOKEN', val)
    },
    // 存储用户信息
    setUserInfo (context, val) {
      context.commit('SET_USER_INFO', val)
    },
    setConfigs (context, val) {
      context.commit('SET_CONFIGS', val)
    },
    // 产品申请表单
    setShowApply (context, val) {
      context.commit('SET_SHOW_APPLY', val)
    },
  },
  getters: {
    userToken: state => state.userToken,
    userInfo: state => state.userInfo,
    configs: state => state.configs,
    showApply: state => state.showApply,
  },
  modules: {
  }
})
