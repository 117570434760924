import request from "@/utils/request.js"

// 政策服务
export const getArticle = (data) => {
  return request({
    method: 'get',
    url: '/getarticle',
    headers: {
      isStop: true
    },
    params: data
  })
}

// 文章详情
export const getArticleDetails = (id) => {
  return request({
    method: 'get',
    url: '/getarticleinfo',
    headers: {
      token: true
    },
    params: { id }
  })
}

// 最新政策
export const getNewArticle = (data) => {
  return request({
    method: 'get',
    url: '/getnewarticle',
    params: data
  })
}

// 增加活动浏览量
export const addReadArticle = (id) => {
  return request({
    method: 'post',
    url: '/addarticleread',
    headers: {
      token: true
    },
    data: { id }
  })
}

// 文章收藏
export const collectArticle = (id) => {
  return request({
    method: 'post',
    url: '/articlecollect',
    headers: {
      token: true
    },
    data: { id }
  })
}

// 微信分享
export const setShare = (url) => {
  return request({
    method: 'post',
    url: '/share',
    data: {
      url
    }
  })
}