// 活动专场
import request from "@/utils/request.js"

// 所有活动
export const getAllActivity = (data) => {
  return request({
    method: 'get',
    url: '/getactivity',
    params: data
  })
}
// 近期活动
export const getNewActivity = () => {
  return request({
    method: 'get',
    url: '/getnewactivity',
  })
}
// 往期活动
export const getPastActivity = () => {
  return request({
    method: 'get',
    url: '/getpastactivity',
  })
}
// 活动排行
export const getCheckActivity = () => {
  return request({
    method: 'get',
    url: '/getcheckactivity'
  })
}

// 活动详情
export const getActivityDetails = (id) => {
  return request({
    method: 'post',
    url: '/getactivityinfo',
    data: {
      id
    }
  })
}

// 相关活动
export const getActivityOther = () => {
  return request({
    method: 'get',
    url: '/getactivityabout',
  })
}

// 增加活动浏览量
export const setReadactivity = (id) => {
  return request({
    method: 'post',
    url: '/readactivity',
    data: {
      id
    }
  })
}