
// 存
export const setItem = (key, value, storage = 'localStorage') => {
  window[storage].setItem(key, JSON.stringify(value));
}

// 取
export const getItem = (key, storage = 'localStorage') => {
  return JSON.parse(window[storage].getItem(key)) || null;
}

// 删
export const removeItem = (key, storage = 'localStorage') => {
  window[storage].removeItem(key);
}

// 清空
export const clear = (storage = 'localStorage') => {
  window[storage].clear();
}
