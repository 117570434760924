/** 头部 */
<template>
  <div class="my-header back-fff">
    <div v-if="$isPc || ($route.matched.some(record => record.meta.isPcHead))"
      class="content align-center justify-between">
      <section class="align-center flex-1">
        <router-link to="/">
          <el-image class="logo mr-20" :src="require($isPc?'@/assets/images/logo.png':'@/assets/images/logo2.png')"
            fit="contain">
          </el-image>
        </router-link>
        <el-menu v-show="$isPc&&!$route.matched.some(record => record.meta.hideNav)" mode="horizontal"
          :default-active="activeIndex" router class="flex-1 el-menu-demo ml-20" background-color="#FFFFFF"
          text-color="#7F8AA4" active-text-color="#347EED">
          <el-menu-item v-for="(nav,index) in navs" :key="index" :index="nav.route_url">
            {{nav.name}}
          </el-menu-item>
        </el-menu>
      </section>
      <section>
        <!-- 未登录 -->
        <div v-if="!userToken || !userInfo" class="login-btns">
          <el-button type="text">
            <router-link to="/account/login" replace>登录</router-link>
          </el-button>
          <el-button type="text">
            <router-link to="/account/register" replace>注册</router-link>
          </el-button>
        </div>
        <!-- 已登录 -->
        <div v-if="userToken && userInfo" class="user-info">
          <el-dropdown ref="userDrop" class="ml-22" trigger="click" :hide-on-click="false" @command="handleCommand">
            <span class="el-dropdown-link">
              {{userInfo.username}}
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown" class="user-info-dropdown">
              <el-dropdown-item command="my"> 个人中心 </el-dropdown-item>
              <el-dropdown-item command="logout">
                <el-popconfirm title="确定退出登录吗？" @confirm="logout">
                  <span slot="reference">
                    退出登录
                  </span>
                </el-popconfirm>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </section>
    </div>
    <div v-else class="content align-center">
      <el-page-header class="el-icon-arrow-left" @back="goBack" :content="$route.meta.detailsTitle"> </el-page-header>
    </div>
  </div>
</template>
<script>
import { logout } from '@/api/account'
import { setItem, getItem } from '@/utils/storage'
import { mapGetters } from 'vuex'
import Search from '@/components/Search.vue'
export default {
  name: 'Header',
  components: {
    Search
  },
  data () {
    return {
      activeIndex: getItem('router_url', 'sessionStorage') || this.$route.path,
      navs: [
        { name: '首页', route_url: '/home' },
        { name: '融资服务', route_url: '/serve/product' },
        { name: '活动专场', route_url: '/activity' },
        { name: '政策服务', route_url: '/policy' },
        { name: '新闻动态', route_url: '/news' },
        { name: '运营联盟', route_url: '/union' },
        { name: '关于我们', route_url: '/about_us' },
      ],
    }
  },
  computed: {
    ...mapGetters([
      'userToken',
      'userInfo',
    ])
  },
  watch: {
    $route (to, from) {
      if (!to.meta.isDetails) {
        setItem('router_url', to.path, 'sessionStorage')
      }
      this.activeIndex = to.meta.isDetails ? getItem('router_url', 'sessionStorage') : to.path
    },
    userToken (newVal) {
      if (!newVal && this.$route.matched.some(record => record.meta.loginAuth)) {
        this.$router.replace('/')
      }
    }
  },
  methods: {
    // 登录成功后切换
    async handleCommand (command) {
      switch (command) {
        case 'my':
          this.$router.push('/my')
          this.$refs.userDrop.hide()
          break;
        default:
          break;
      }
    },
    async logout () {
      await logout()
      this.$store.dispatch('setUserToken', '')
      this.$store.dispatch('setUserInfo', '')
    },
    // 返回上一页
    goBack () {
      this.$router.go(-1);//返回上一层
    }
  },
}
</script>
<style lang="scss" scoped>
.my-header {
  width: 100%;
  height: 100px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  box-shadow: 0px 0px 7px 0px rgba(60, 79, 141, 0.2);
}

// 导航
.el-menu.el-menu--horizontal {
  border: none;
  .el-menu-item {
    height: 45px;
    line-height: 45px;
    font-size: 16px;
    padding: 0;
    margin: 0 20px;
    border: none;
    &:hover {
      background-color: transparent !important;
      // color: #2b62b2 !important;
    }
    &.is-active {
      font-weight: bold;
      border: none;
    }
  }
}
.header-search {
  i {
    font-size: 18px;
    font-weight: bold;
  }
}

@media (max-width: 767px) {
  .my-header {
    height: 60px;
    background-color: #2b62b2;
    .logo {
      height: 40px;
      margin-left: 10px;
    }
    .user-info,
    .login-btns {
      margin-right: 10px;
      .el-dropdown-link,
      a {
        color: #fff;
      }
    }
  }
  /deep/ .header-search {
    .el-input {
      // float: left;
      max-width: 88%;
      padding-right: 10px;
      .el-input__suffix {
        right: 10px;
      }
    }
  }
  /deep/ .el-page-header {
    width: 100%;
    padding: 0 10px;
    color: #fff;
    .el-page-header__left {
      margin-right: 0;
      &::after {
        display: none;
      }
      & > i {
        display: none;
      }
    }
    .el-page-header__content {
      flex: 1;
      font-size: 14px;
      color: #fff;
      text-align: center;
      margin-left: -52px;
    }
  }
}
</style>