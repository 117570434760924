// 登录注册
import request from "@/utils/request.js"

// 验证码
export const sendcaptcha = (phone, type) => {
  return request({
    method: 'POST',
    url: '/sendcaptcha',
    data: {
      phone,
      type
    }
  })
}

// 注册
export const register = (data) => {
  return request({
    method: 'post',
    url: '/register',
    data
  })
}

// 登录
export const login = (data) => {
  return request({
    method: 'post',
    url: '/login',
    data
  })
}

// 修改用户名称
export const updateinfo = (data) => {
  return request({
    method: 'post',
    url: '/updateinfo',
    headers: {
      token: true
    },
    data
  })
}

// 修改密码、忘记密码
export const updatepassword = (data) => {
  return request({
    method: 'post',
    url: '/updatepassword',
    headers: {
      token: true
    },
    data
  })
}

// 验证旧手机验证码
export const verifycaptcha = (data) => {
  return request({
    method: 'post',
    url: '/verifycaptcha',
    headers: {
      token: true
    },
    data
  })
}

// 修改手机号
export const updatephone = (data) => {
  return request({
    method: 'post',
    url: '/updatephone',
    headers: {
      token: true
    },
    data
  })
}

// 退出登录
export const logout = () => {
  return request({
    method: 'get',
    url: '/logout',
    headers: {
      token: true
    }
  })
}